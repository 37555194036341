import { useTranslation } from 'react-i18next';
import { NextPage } from 'next';
import tracking from 'src/tracking';
import NotFoundTemplate, { NotFoundTemplateProps } from '../templates/NotFoundTemplate';
import { ssrPersistedQuery } from '../helpers/SSRHelpers';
import { initWithLangAndLabels } from '../i18n';
import { Icons } from '../components/atoms/Icon';
import { ActionButtonVariant } from '../components/atoms/ActionButton/styled';
import Router from '../routes/Router';
import routes from '../routes/definitions';
import { Configuration } from '../graphql/Services';
import { wediaImagesTransform } from '../relay/Common/wedia';
import type { PageProps } from '../relay/Common/PageProps';
import HeaderWrapper from '../relay/Header/HeaderWrapper';
import FooterWrapper from '../relay/Footer/FooterWrapper';
export type NotFoundPageProps = PageProps & {
  //
};
const NotFoundPage: NextPage<NotFoundPageProps> = ({
  configuration
}) => {
  const {
    t
  } = useTranslation();
  const notFoundProps: NotFoundTemplateProps = {
    title: t('404_title'),
    subtitle: t('404_text'),
    header: <HeaderWrapper data={configuration?.header} socials={configuration?.footer?.socialLinks} />,
    footer: <FooterWrapper data={configuration?.footer} />,
    imageProps: {
      maxWidth: 2000,
      width: 1440,
      height: 1048,
      withHD: true,
      images: wediaImagesTransform(configuration?.notFound?.image),
      alt: configuration?.notFound?.image?.alt ?? ''
    },
    logoProps: {
      maxWidth: 160,
      width: 160,
      height: 160,
      withHD: true,
      alt: t('seo_logo_alt'),
      images: [{
        src: '/static/assets/images/common/logo-220-min.png',
        size: 586
      }, {
        src: '/static/assets/images/common/logo-220-min.webp',
        size: 160
      }]
    },
    buttonProps: {
      label: t('404_button'),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight
      },
      variant: ActionButtonVariant.secondary,
      href: Router.getRouteUrl(routes.home),
      onClick: () => tracking.cta(t('404_button'), t('404_title'))
    }
  };
  return <NotFoundTemplate {...notFoundProps} />;
};
export async function getStaticProps() {
  const i18n = initWithLangAndLabels('fr', {});
  const configurationRs = await ssrPersistedQuery(Configuration.queries.configuration);
  const configuration = configurationRs?.data ?? null;
  return {
    props: {
      i18n,
      configuration
    }
  };
}
export default NotFoundPage;